export const post = function(url, data) {
  return fetch(url, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.origin,
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    },
    method: 'POST', credentials: 'include', body: JSON.stringify(data),
  });
};

export const getByFetch = function(url) {
  return fetch(url, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.origin,
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    },
    method: 'GET', credentials: 'include',
  });
};

export const poll = function(fn, timeout, interval, onTimeout) {
  const endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;

  const checkCondition = function(resolve, reject) {
    // If the condition is met, we're done!
    const result = fn();
    if (result) {
      resolve(result);
    } else if (Number(new Date()) < endTime) {
      // If the condition isn't met but the timeout hasn't elapsed, go again
      setTimeout(checkCondition, interval, resolve, reject);
    } else {
      // Didn't match and too much time, reject!
      onTimeout();
    }
  };

  return new Promise(checkCondition);
};
