const BASE_API_URL = process.env.API_URL;

export const ORDER_API_URL= BASE_API_URL + '/waterstation/order';
export const IS_FILL_ENABLE_URL= BASE_API_URL + '/waterstation/isFillEnable/';
export const IS_STATION_ONLINE= BASE_API_URL + '/waterstation/isStationOnline/';
export const GALON_AVAILABLE= BASE_API_URL + '/waterstation/galon/available/';
export const FILL_URL = BASE_API_URL + '/waterstation/fillNow';
export const FEEDBACK_URL = BASE_API_URL + '/feedback';
export const GET_USER_URL = BASE_API_URL + '/user';
export const GET_USER_BONUS_TOPUP_AVAILABLE = BASE_API_URL + '/payment/isTopUpBonusAvailable';
export const GET_USER_ORDER_HISTORY = BASE_API_URL + '/user/orders';
export const GET_USER_ORDER_COUNT = BASE_API_URL + '/user/orderCount';
export const GET_IZIPAY_URL = BASE_API_URL + '/user/izipay';
export const LOGIN_URL = BASE_API_URL + '/auth/login';
export const REGISTER_URL = BASE_API_URL + '/auth/register';
export const LOGIN_GOOGLE_PAGE_URL = BASE_API_URL + '/google';
export const FREE_FILL_API_URL = BASE_API_URL + '/waterstation/freeRefillRemaining';
export const BONUS_REGISTER = BASE_API_URL + '/bonusRegister';
export const CHECKOUT_URL = BASE_API_URL + '/payment/checkout';
export const PAYMENT_TOKEN_URL = BASE_API_URL + '/payment/getPaymentToken';
